import Images from "@/src/component/image/Images";
import Image from "next/image";
import { ReactNode } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import SosmedSection from "./SosmedSection";

type KeysProp = "default" | "become_seller" | "tab_buyer" | "tab_seller";

const initialTitle = (
  <h1>
    Not having <br />
    Storage Hunt App Yet? Download Now
  </h1>
);

const initialDesc = (
  <p className="text-primary-900 text-title3/8 xl:text-title3/9 mt-3 text-center lg:text-left">
    Join the waitlist to stay updated and get early access to StorageHunt.
  </p>
);

const imageRender = {
  default: [
    "/images/app/image-1.png",
    "/images/app/image-9.png",
    "/images/app/image-3.png",
    "/images/app/image-17.png",
  ],
  become_seller: [
    "/images/app/image-19-isbuyer.png",
    "/images/app/image-9-new.png",
    "/images/app/image-18-isbuyer.png",
    "/images/app/image-17-new.png",
  ],
  tab_buyer: [
    "/images/app/image-1.png",
    "/images/app/image-9.png",
    "/images/app/image-3.png",
    "/images/app/image-17.png",
  ],
  tab_seller: [
    "/images/app/image-19-isbuyer.png",
    "/images/app/image-9-new.png",
    "/images/app/image-18-isbuyer.png",
    "/images/app/image-17-new.png",
  ],
} as {
  [key in KeysProp]: Array<string>;
};

const AppPromotionSection = ({
  imgRender = "default",
  title = initialTitle,
  desc = initialDesc,
  type,
  alt = "StorageHunt.com",
  className = "",
}: {
  imgRender?: KeysProp;
  title?: ReactNode;
  desc?: ReactNode;
  type?: string;
  alt?: string;
  className?: string;
}) => {
  return (
    <>
      {/* APP PROMOTION */}
      <div
        className={`mt-16 lg:mt-32 lg:container lg:px-4.5 2xl:px-default ${className}`}
      >
        <div className="h-auto bg-gradient-to-b from-[#FFFAE7] from-5% to-primary-500 to-95% rounded-[2.125rem] flex lg:flex-row flex-col  ">
          <div className="lg:w-1/2 flex flex-col items-center justify-between lg:px-4.5">
            <div className="lg:max-w-[34.5rem] w-full lg:h-3/5 flex flex-col justify-center items-center lg:items-start lg:justify-end px-4.5 lg:px-0 lg:pt-0 pt-[2.688rem]">
              <div
                className={`text-[2.5rem]/[3.75rem] ${
                  type
                    ? "lg:text-[2.5rem]/[3rem]"
                    : "lg:text-[2.5rem]/[3.75rem]"
                } xl:text-title1/[4.5rem] font-tilt-warp text-neutral-900 text-center lg:text-left lg:max-w-full max-w-md`}
              >
                {title}
              </div>

              {desc}

              {/* SOSMED */}
              <SosmedSection
                className={`${type ? "lg:mt-7" : "lg:mt-9.5"} lg:gap-7`}
              />
            </div>
            <div className="w-full relative h-2/5 lg:block hidden">
              <AnimationOnScroll
                className="absolute bottom-0 right-[4rem]"
                animateIn="animate__slideInUp"
                animateOnce
              >
                <Image
                  src="/images/Illustration/taxi.svg"
                  alt={alt || "Not having Storage Hunt App Yet? Download Now"}
                  unoptimized
                  width={0}
                  height={0}
                  loading="eager"
                  className="w-auto"
                />
              </AnimationOnScroll>
            </div>
          </div>
          <div className="lg:w-1/2 flex gap-8 lg:pr-16 items-center justify-center">
            <div className="block lg:flex flex-col lg:gap-[3.313rem] lg:justify-between justify-end h-[25rem] lg:h-auto w-[90%] lg:w-1/2">
              {/* IMAGE 1 */}
              <AnimationOnScroll
                className="lg:block hidden"
                animateIn="animate__fadeIn"
                animateOnce
              >
                <Image
                  src={imageRender[imgRender][0]}
                  alt={`${alt} 1`}
                  unoptimized
                  width={0}
                  height={0}
                  loading="eager"
                  className="w-full "
                />
              </AnimationOnScroll>

              {/* IMAGE 2 */}
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                <Image
                  src={imageRender[imgRender][1]}
                  alt={`${alt} 2`}
                  unoptimized
                  width={0}
                  height={0}
                  loading="eager"
                  className="w-full mt-6 lg:mt-0"
                />
              </AnimationOnScroll>
            </div>
            <div className="lg:flex flex-col gap-[3.313rem] hidden justify-between h-full w-1/2">
              {/* IMAGE 3 */}
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                <Image
                  src={imageRender[imgRender][2]}
                  alt={`${alt} 3`}
                  unoptimized
                  width={0}
                  height={0}
                  loading="eager"
                  className="w-full"
                />
              </AnimationOnScroll>

              {/* IMAGE 4 */}
              <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                <Image
                  src={imageRender[imgRender][3]}
                  alt={`${alt} 4`}
                  unoptimized
                  width={0}
                  height={0}
                  loading="eager"
                  className="w-full"
                />
              </AnimationOnScroll>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AppPromotionSection;
