import Images from "@/src/component/image/Images";
import { ReactNode, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
import DoorOpenSection from "./DoorOpenSection";

const GradientSection = ({ children }: { children: ReactNode }) => {
  return (
    <>
      <div className="bg-gradient-to-b from-primary-500 to-primary-500/10 lg:to-white relative w-full">
        <div className="absolute top-[-6rem] w-[200%] bg-gradient-to-b from-primary-500 from-30% to-transparent rounded-[100%] left-[50%] translate-x-[-50%] pt-[50%] z-0"></div>
        {/* MOBILE CITY */}
        <div className="lg:container lg:px-4.5 2xl:px-default pb-16 lg:pb-[4.25rem] z-0 relative">
          <DoorOpenSection />

          <>{children}</>
        </div>
      </div>
    </>
  );
};

export default GradientSection;
