import Images from "@/src/component/image/Images";
import { AnimationOnScroll } from "react-animation-on-scroll";

const DoorOpenSection = ({}) => {
  return (
    <>
      <div className="relative w-full h-[4.5rem] lg:h-28 xl:h-44 2xl:h-[9rem]">
        <div className="absolute bottom-0 lg:-bottom-16 xl:bottom-4 2xl:bottom-8 z-10 w-full flex justify-center">
          {/* STORAGE DESKTOP */}
          <Images
            src="/images/Illustration/storage-only.svg"
            alt="StorageHunt.com | Online Storage Unit Auction Marketplace"
            className="w-[95%] xl:w-full h-[34.875rem] hidden lg:block"
          />

          {/* MOBILE DESKTOP */}
          <Images
            src="/images/Illustration/storage-mobile.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-full block lg:hidden"
          />
        </div>

        {/* DESKTOP */}
        <div className="absolute z-10 bottom-[90%] xl:bottom-[81%] 2xl:bottom-full left-[41.9%] xl:left-[41.5%] 2xl:left-[41.5%] w-[10.3rem] xl:w-[13.6rem] 2xl:w-[15rem] bg-[url('/images/Illustration/door-2.svg')] h-[111px] xl:h-[88%] 2xl:h-[166px] bg-no-repeat bg-[#6B707C] rounded-t-[.2rem] bg-center bg-[length:105%_105%] xl:bg-[length:103%_103%] 2xl:bg-[length:105%_105%] hidden lg:block xl:border-t-[3px] xl:border-x-[3px] border-[#0F172A] bg-anime"></div>

        <AnimationOnScroll
          className="absolute z-20 bottom-0 left-8"
          animateIn="animate__zoomIn"
          animateOnce
          delay={2500}
          scrollableParentSelector="#bg-door-open"
        >
          <Images
            src="/images/Illustration/boy2.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-[80%] xl:w-auto hidden lg:block"
          />
        </AnimationOnScroll>

        <AnimationOnScroll
          className="absolute z-20 bottom-0 left-[22%]"
          animateIn="animate__zoomIn"
          animateOnce
          delay={2800}
          scrollableParentSelector="#bg-door-open"
        >
          <Images
            src="/images/Illustration/girl.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-[80%] xl:w-auto hidden lg:block"
          />
        </AnimationOnScroll>

        <AnimationOnScroll
          className="absolute z-20 bottom-0 xl:bottom-8 left-[35.2%]"
          animateIn="animate__zoomIn"
          animateOnce
          delay={2000}
          scrollableParentSelector="#bg-door-open"
        >
          <Images
            src="/images/Illustration/furniture.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-[80%] xl:w-auto hidden lg:block"
          />
        </AnimationOnScroll>

        <AnimationOnScroll
          className="absolute z-20 bottom-0 right-[5.5%]"
          animateIn="animate__zoomIn"
          animateOnce
          delay={2300}
          scrollableParentSelector="#bg-door-open"
        >
          <Images
            src="/images/Illustration/boy1.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-[80%] xl:w-auto hidden lg:block"
          />
        </AnimationOnScroll>
      </div>
    </>
  );
};

export default DoorOpenSection;
