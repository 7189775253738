import Images from "@/src/component/image/Images";
import { clouds } from "@/src/helpers/list";
import { isMobile } from "react-device-detect";
import { useEffect, useState } from "react";
import SosmedSection from "./SosmedSection";
import SendSMS from "./SendSMS";

const HeroSection = ({ className }: { className?: string }) => {
  const [mode, setMode] = useState<"mobile" | "desktop" | null>(null);

  useEffect(() => {
    if (isMobile) {
      setMode("mobile");
    } else {
      setMode("desktop");
    }
  }, []);

  return (
    <>
      <div
        id="anime"
        className={`h-[37.5rem] md:h-[65rem] lg:h-[43rem] 2xl:h-[48rem] relative ${className}`}
      >
        {clouds.map((e, key) => (
          <Images
            key={key}
            src="/images/Illustration/cloud.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className={`absolute ${e.className} ${e.initial}`}
          />
        ))}
        {/* ANIME */}

        {clouds.map((e, key) => (
          <Images
            key={key}
            src="/images/Illustration/cloud.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className={`absolute ${e.id} ${e.className}`}
          />
        ))}

        <div className="lg:container px-4.5 lg:px-default h-full relative">
          <div className="flex flex-col items-center justify-start h-full lg:mt-[3.125rem] mt-16 relative z-10">
            <h1 className="text-center text-neutral-900 text-title3/[36px] md:text-title/[96px]">
              Bid On{" "}
              <span className="font-tilt-warp font-normal">Storage Unit</span>{" "}
              <br />
              <span className="font-tilt-warp">Auctions</span> Like{" "}
              <span className="font-tilt-warp">A Pro</span>
            </h1>
            {mode === "desktop" && <SendSMS />}
            {mode === "mobile" && (
              <>
                <div className="mt-3.5 text-large-none text-neutral-600">
                  Get The App
                </div>

                {/* SOSMED */}
                <SosmedSection className="lg:mt-8.5 md:gap-7" />
              </>
            )}
          </div>
        </div>
        {/* DESKTOP CITY */}
        <div className="absolute left-0 top-[35%] 2xl:top-[41%] z-0 hidden lg:block">
          <Images
            src="/images/Illustration/bg-city-1.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="2xl:w-[22rem]"
          />
        </div>
        <div className="absolute right-0 top-[32%] 2xl:top-[30%] z-0 hidden lg:block">
          <Images
            src="/images/Illustration/bg-city-2.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="2xl:w-[36rem]"
          />
        </div>
        <div className="absolute top-[14.5rem] md:top-[20rem] left-4 md:left-0 md:w-full z-0 lg:hidden">
          <Images
            src="/images/Illustration/bg-city-3.svg"
            alt="Bid On Storage Unit Auctions Like A Pro"
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
