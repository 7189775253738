import { Money4 } from "iconsax-react";
import { ReactNode } from "react";
import {
  AppleBoldIcon,
  Building2OutlineIcon,
  CardPosIcon,
  CoinIcon,
  CupOutlineIcon,
  DiscoverOutlineIcon,
  DollarOutlineIcon,
  FbBoldIcon,
  FBIcon,
  FlagIcon,
  GpBoldIcon,
  House2OutlineIcon,
  IgBoldIcon,
  LinkedinIcon,
  LocationOutlineIcon,
  MailIcon,
  MobileOutlineIcon,
  MoneyReciveOutlineIcon,
  PercentCircleIcon,
  PersonalCardIcon,
  Profile2UserOutlineIcon,
  ShopOutlineIcon,
  StripeIcon,
  TickCirleIcon,
  TickCirleOutlineIcon,
  TimerIcon,
  TimerStartIcon,
  TwitchBoldIcon,
  TwitterIcon,
  WalletAddIcon,
  YoutubeBoldIcon,
} from "../component/icon/SvgIcon";
import { ObjectDynamicProp, PricingListDataProps } from "../utils/globalTypes";

export const mainMenu = [
  {
    id: "how-it-work",
    link: "/how-it-work",
    menu: "How it Works",
    active: false,
  },
  {
    id: "become-seller",
    link: "/become-seller",
    menu: "Become a Seller",
    active: false,
  },
  {
    id: "pricing",
    link: "/pricing",
    menu: "Pricing",
    active: false,
  },
  {
    id: "review",
    link: "/review",
    menu: "Reviews",
    active: false,
  },
] as {
  id: string;
  link: string;
  menu: string;
  active: boolean;
}[];

export const footerMenu = {
  company: {
    title: "Company",
    menu: [
      {
        id: "about",
        link: "/about",
        menu: "About",
      },
      {
        id: "our-service",
        link: "/our-service",
        menu: "Our Services",
      },
      {
        id: "blog",
        link: "/blog",
        menu: "Blog",
      },
    ],
  },
  app: {
    title: "The App",
    menu: [
      {
        id: "how-it-work",
        link: "/how-it-work",
        menu: "How It Works",
      },
      {
        id: "become-seller",
        link: "/become-seller",
        menu: "Become Seller",
      },
      {
        id: "platform-fee",
        link: "/platform-fee",
        menu: "Platform Fee",
      },
      {
        id: "review",
        link: "/review",
        menu: "Reviews",
      },
    ],
  },
  support: {
    title: "Support",
    menu: [
      {
        id: "contact",
        link: "/contact",
        menu: "Contact Us",
      },
      {
        id: "faq",
        link: "/faq",
        menu: "FAQ",
      },
      {
        id: "term-of-service",
        link: "/term-of-service",
        menu: "Terms of Service",
      },
      {
        id: "privacy-policy",
        link: "/privacy-policy",
        menu: "Privacy Policy",
      },
    ],
  },
  contact: {
    title: "Contact Us",
    menu: [
      {
        id: "support",
        link: "/support",
        menu: "Support",
      },
      {
        id: "contact",
        link: "/contact",
        menu: "Contact Us",
      },
    ],
  },
} as {
  [key: string]: {
    title: string;
    menu: { id: string; link: string; menu: string }[];
  };
};

export const followUs = [
  {
    id: "ig",
    name: "Instagram",
    link: "https://www.instagram.com/",
    Icon: IgBoldIcon,
  },
  {
    id: "twitch",
    name: "Twitch",
    link: "https://www.twitch.tv/",
    Icon: TwitchBoldIcon,
  },
  {
    id: "fb",
    name: "Facebook",
    link: "https://www.facebook.com/",
    Icon: FbBoldIcon,
  },
  {
    id: "youtube",
    name: "Youtube",
    link: "https://www.youtube.com/",
    Icon: YoutubeBoldIcon,
  },
] as {
  id: string;
  name: string;
  link: string;
  Icon: (props: any) => JSX.Element;
}[];

export const availAt = [
  {
    id: "iphone",
    name: "Iphone",
    link: "#",
    Icon: AppleBoldIcon,
  },
  {
    id: "playstore",
    name: "Playstore",
    link: "#",
    Icon: GpBoldIcon,
  },
] as {
  id: string;
  name: string;
  link: string;
  Icon: (props: any) => JSX.Element;
}[];

export const findAuctionFrom = [
  {
    link: "/images/produk/uhaul.svg",
    alt: "Uhaul",
    className: "w-full md:h-auto ",
  },
  {
    link: "/images/produk/public-storage2.svg",
    alt: "Public Storage",
    className: "w-full md:h-auto ",
  },
  {
    link: "/images/produk/extraspace2.svg",
    alt: "Extra Space",
    className: "w-full md:h-auto",
  },
  {
    link: "/images/produk/cubesmart2.svg",
    alt: "Cubesmart",
    className: "w-full md:h-auto",
  },
  {
    link: "/images/produk/lifestorage2.svg",
    alt: "LifeStorage",
    className: "w-full md:h-auto",
  },
  {
    link: "/images/produk/simplyself2.svg",
    alt: "SimplySelf",
    className: "w-full md:h-auto",
  },
] as {
  link: string;
  alt: string;
  className: string;
}[];

export const testimoni = [
  {
    img: "/images/avatar/avatar-1.png",
    desc: "I recently moved into a smaller apartment and needed to find creative ways to store my belongings. I came across Storage Hunt and it has been a lifesaver!",
    desc_ea:
      "When you hunt for storage units with StorageHunt, you’re part of a community that prioritizes an honest experience. We have the tools in the app for you to get help easily, if you ever need it.",
    name: "Manuel Araujo Kristensen",
    address: "San Jose, California",
    className: "lg:w-[3.625rem] lg:h-[3.625rem] w-11.5 h-11.5",
  },
  {
    img: "/images/avatar/avatar-2.png",
    desc: "I recently moved into a smaller apartment and needed to find creative ways to store my belongings. I came across Storage Hunt and it has been a lifesaver!",
    desc_ea:
      "When you hunt for storage units with StorageHunt, you’re part of a community that prioritizes an honest experience. We have the tools in the app for you to get help easily, if you ever need it.",
    name: "Manuel Araujo Kristensen",
    address: "San Jose, California",
    className: "lg:w-[4.75rem] lg:h-[4.75rem] w-[3.375rem] h-[3.375rem]",
  },
  {
    img: "/images/avatar/avatar-3.png",
    desc: "I recently moved into a smaller apartment and needed to find creative ways to store my belongings. I came across Storage Hunt and it has been a lifesaver!",
    desc_ea:
      "When you hunt for storage units with StorageHunt, you’re part of a community that prioritizes an honest experience. We have the tools in the app for you to get help easily, if you ever need it.",
    name: "Manuel Araujo Kristensen",
    address: "San Jose, California",
    className: "lg:w-[5.25rem] lg:h-[5.25rem] w-16 h-16",
  },
  {
    img: "/images/avatar/avatar-4.png",
    desc: "I recently moved into a smaller apartment and needed to find creative ways to store my belongings. I came across Storage Hunt and it has been a lifesaver!",
    desc_ea:
      "When you hunt for storage units with StorageHunt, you’re part of a community that prioritizes an honest experience. We have the tools in the app for you to get help easily, if you ever need it.",
    name: "Manuel Araujo Kristensen",
    address: "San Jose, California",
    className: "lg:w-[4.75rem] lg:h-[4.75rem] w-[3.375rem] h-[3.375rem]",
  },
  {
    img: "/images/avatar/avatar-5.png",
    desc: "I recently moved into a smaller apartment and needed to find creative ways to store my belongings. I came across Storage Hunt and it has been a lifesaver!",
    desc_ea:
      "When you hunt for storage units with StorageHunt, you’re part of a community that prioritizes an honest experience. We have the tools in the app for you to get help easily, if you ever need it.",
    name: "Manuel Araujo Kristensen",
    address: "San Jose, California",
    className: "lg:w-[3.625rem] lg:h-[3.625rem] w-11.5 h-11.5",
  },
];

export const stats = [
  {
    name: "2K+",
    title: "On Going Auction",
    subtitle: "There’s so many on going auction right now",
    Icon: House2OutlineIcon,
  },
  {
    name: "$10",
    title: "Average Bid",
    subtitle: "Many auctions has competitive bid amount",
    Icon: DollarOutlineIcon,
  },
  {
    name: "1,5K+",
    title: "Facility Total",
    subtitle: "Many facilities all registered across united states",
    Icon: LocationOutlineIcon,
  },
  {
    name: "1K+",
    title: "Buyers Total",
    subtitle: "Many buyers across the world are joined to hunt",
    Icon: Profile2UserOutlineIcon,
  },
] as {
  name: string;
  title: string;
  subtitle: string;
  Icon: (props: any) => JSX.Element;
}[];

export const become_a_seller = [
  {
    title: "Download and Join",
    subtitle:
      "Start download the app in app store or playstore and then register and confirm yourself",
    Icon: MobileOutlineIcon,
    media: "/images/burger.gif",
  },
  {
    title: "Switch To Seller",
    subtitle:
      "Switch to seller to began registering your facility and start selling your unit",
    Icon: ShopOutlineIcon,
    media: "/images/giphy.gif",
  },
  {
    title: "Create Facility",
    subtitle:
      "Start create your own facility and verified and invite your team to manage your facility",
    Icon: Building2OutlineIcon,
    media: "/images/burger.gif",
  },
  {
    title: "Create Auction",
    subtitle:
      "Start create your auction to your facility and setup the auction description to make buyer interest",
    Icon: House2OutlineIcon,
    media: "/images/giphy.gif",
  },
] as {
  title: string;
  subtitle: string;
  Icon: (props: any) => JSX.Element;
  media: string;
}[];

export const new_how_it_work = [
  {
    title: "Download The App",
    subtitle:
      "Download the StorageHunt app from the App Store or Google Play Store, and proceed to sign up.",
    Icon: MobileOutlineIcon,
    media: "/images/video/download-the-app.svg",
    type: "image",
  },
  {
    title: "Make A Bid",
    subtitle:
      "You can either grant the app access to your location or manually select a location to search for auctions and commence bidding.",
    Icon: CoinIcon,
    media: "/images/video/Bidding.mp4",
    type: "video",
  },
  {
    title: "Verify Your ID",
    subtitle:
      "The first time you place a bid, you will need to verify your ID to guarantee the authenticity of our users.",
    Icon: PersonalCardIcon,
    media: "/images/video/verify-id.mp4",
    type: "video",
  },
  {
    title: "App-Based Payment",
    subtitle:
      "Once you've won an auction, we will automatically charge the payment method on your account.",
    Icon: CardPosIcon,
    media: "/images/video/payment-method.mp4",
    type: "video",
  },
] as {
  title: string;
  subtitle: string;
  Icon: (props: any) => JSX.Element;
  media: string;
  type: "image" | "video";
}[];

export const how_it_work = [
  {
    title: "Download and Join",
    subtitle:
      "Start download the app in app store or playstore and then register and confirm yourself",
    Icon: MobileOutlineIcon,
    media: "/images/giphy.gif",
  },
  {
    title: "Explore The Auction",
    subtitle:
      "Find auction that you interested in based on images, video or description",
    Icon: DiscoverOutlineIcon,
    media: "/images/burger.gif",
  },
  {
    title: "Bid on it",
    subtitle: "Place your bidding to the unit auction and outbid the others",
    Icon: MoneyReciveOutlineIcon,
    media: "/images/giphy.gif",
  },
  {
    title: "You’re won",
    subtitle:
      "Highest bid can take unit auction by paying and after that you can go to the facility location and clean out the unit",
    Icon: CupOutlineIcon,
    media: "/images/burger.gif",
  },
] as {
  title: string;
  subtitle: string;
  Icon: (props: any) => JSX.Element;
  media: string;
}[];

export const clouds = [
  {
    id: "cloud-1",
    className: "top-9",
    initial: "initial-cloud-1",
  },
  {
    id: "cloud-2",
    className: "-top-11",
    initial: "initial-cloud-2 scale-[.35]",
  },
  {
    id: "cloud-3",
    className: "-top-2",
    initial: "initial-cloud-3 scale-[.35]",
  },
  {
    id: "cloud-4",
    className: "top-[7rem]",
    initial: "initial-cloud-4",
  },
  {
    id: "cloud-5",
    className: "top-[11rem]",
    initial: "scale-[.35] initial-cloud-5",
  },
  {
    id: "cloud-6",
    className: "top-[13rem]",
    initial: "scale-[.35] initial-cloud-6",
  },
] as {
  id: string;
  initial: string;
  className: string;
}[];

export const how_it_work_bas = [
  {
    title: "Download The App",
    subtitle:
      "Download the StorageHunt app from the App Store or Google Play Store, and proceed to sign up.",
    Icon: MobileOutlineIcon,
    media: "/images/video/download-the-app.svg",
    type: "image",
  },
  {
    title: "Get Your Facility Approved.",
    subtitle:
      "After completing the sign-up process, switch to facility mode and submit your verification documents for facility approval.",
    Icon: TickCirleOutlineIcon,
    media: "/images/video/req_verify.mp4",
    type: "video",
  },
  {
    title: "Establish Your Facility Profile",
    subtitle:
      "After approval, you'll be required to input your facility's details, including operational hours, clean-out deposit amounts, and add any of your team members.",
    Icon: House2OutlineIcon,
    media: "/images/video/create-facility-2.mp4",
    type: "video",
  },
  {
    title: "Initiate Your First Auction",
    subtitle:
      "Commence a new auction by uploading high-quality images, choosing start and end dates, and specifying the duration for bidders to remove the items during the cleanout process.",
    Icon: TimerStartIcon,
    media: "/images/video/create-auction-2.mp4",
    type: "video",
  },
] as {
  title: string;
  subtitle: string;
  Icon: (props: any) => JSX.Element;
  media: string;
  type: "image" | "video";
}[];

export const pricingForFacilities = [
  {
    title: "Platform Fee",
    desc: `<div className="text-large-normal">
    Currently no seller fee
  </div> `,
    Icon: PercentCircleIcon,
    tooltip: false,
  },
  {
    title: "Monthly Fee",
    desc: `<div className="text-large-normal">
    Currently <b>$0.00/</b> per month per facility.
  </div> `,
    Icon: WalletAddIcon,
    tooltip: false,
  },
  {
    title: "Regular Payout",
    desc: `<div className="text-large-normal">
    <b>$1.00/</b> per payout sent to your bank.
  </div> `,
    Icon: MoneyReciveOutlineIcon,
    tooltip: true,
  },
  {
    title: "Instants Payouts",
    desc: `<div className="text-large-normal">
    Receive instant funds for just <b>5%</b> of the payout amount.
  </div>`,
    Icon: TimerIcon,
    tooltip: false,
  },
  {
    title: "Stripe Connect Fee",
    desc: `<div className="text-large-normal">
    A <b>$5.00</b> month fee to cover the cost of your payout account with stripe
  </div>`,
    Icon: StripeIcon,
    tooltip: false,
    className: "col-span-full",
    iconClassName: "bg-secondary-100",
  },
] as PricingListDataProps[];

export const pricingForBuyer = [
  {
    title: "Platform Fee",
    desc: `<div className="text-large-normal">
    A <b>15%</b> surcharge is applied to the total winning auction value.
  </div>`,
    Icon: PercentCircleIcon,
    tooltip: false,
    // valueKey: "buyer",
    // valueChild: "platform_fee",
  },
  {
    title: "Monthly Fee",
    desc: `<div className="text-large-normal">
    <b>Free</b> for the first 3,000 users who sign up, then $15 per month.
  </div>`,
    Icon: WalletAddIcon,
    tooltip: false,
  },
] as PricingListDataProps[];

export const listPayment = [
  "visa",
  "mastercard",
  "american-express",
  "maestro",
  "discover",
  "jcb",
  "unionpay",
  "apple-pay",
  "gpay",
];

export const includePricingFacility = [
  {
    title: "Mobile Application",
    subtitle: "Accessible on iOS or Android",
  },
  {
    title: "Access StorageHunt Network",
    subtitle: "Get access to over 400,000 bidders",
  },
  {
    title: "Deposit Fee Automation",
    subtitle: "Streamlined management of deposit fees",
  },
  {
    title: "Fraud Prevention",
    subtitle: "Buyers are ID-verified to mitigate risks",
  },
  {
    title: "Payouts",
    subtitle: "Receive payments to your business account",
  },
  {
    title: "Tax Collection",
    subtitle: "Collection and remittance handled by us",
  },
  {
    title: "Multiple Facilities",
    subtitle: "Manage several facilities under a single account",
  },
  {
    title: "Team Management",
    subtitle: "Allow your employees to oversee auctions",
  },
  {
    title: "In-App Chat",
    subtitle: "Direct communication with auction winners",
  },
  {
    title: "Support",
    subtitle: "Receive assistance when needed.",
  },
] as { title: string; subtitle: string }[];

export const includePricingBuyer = [
  {
    title: "Mobile Application",
    subtitle: "Available on iOS or Android",
  },
  {
    title: "Watchlists",
    subtitle: "Generate a list of auctions to monitor.",
  },
  {
    title: "Bid Fraud Prevention",
    subtitle: "All bidders are ID-verified to prevent fake bids",
  },
  {
    title: "In-App Chat",
    subtitle: "Communicate with facility’s after you’ve won",
  },
  {
    title: " Priority Support",
    subtitle: "Receive support when you need it",
  },
] as { title: string; subtitle: string }[];

export const companySize = [
  {
    id: "a. 1-99",
    name: "1-99",
  },
  {
    id: "b. 100-999",
    name: "100-999",
  },
  {
    id: "c. 1000-4999",
    name: "1000-4999",
  },
  {
    id: "d. 5000+",
    name: "5000+",
  },
] as {
  id: number | string | null;
  name: string;
}[];

export const auctionVolume = [
  {
    id: "a. 1-19",
    name: "1-19",
  },
  {
    id: "b. 20-49",
    name: "20-49",
  },
  {
    id: "c. 50-99",
    name: "50-99",
  },
  {
    id: "d. 100-999",
    name: "100-999",
  },
] as {
  id: number | string | null;
  name: string;
}[];

export const ayfVolume = [
  {
    id: "1-5",
    name: "1-5",
  },
  {
    id: "6-10",
    name: "6-10",
  },
  {
    id: "11-20",
    name: "11-20",
  },
  {
    id: "20+",
    name: "20+",
  },
] as {
  id: number | string | null;
  name: string;
}[];

export const unitList = [
  {
    label: "(small) Locker",
    value: "small_locker",
  },
  {
    label: "(small) 5'x5'",
    value: "small_5x5",
  },
  {
    label: "(small) 5'x10'",
    value: "small_5x10",
  },
  {
    label: "(medium) 5'x15'",
    value: "medium_5x15",
  },
  {
    label: "(medium) 10'x10'",
    value: "medium_10x10",
  },
  {
    label: "(large) 10'x15'",
    value: "large_10x15",
  },
  {
    label: "(large) 10'x20'",
    value: "large_10x20",
  },
  {
    label: "(large) 10'x25'",
    value: "large_10x25",
  },
  {
    label: "Vehicle Unit 20'",
    value: "vehicle_unit_20",
  },
  {
    label: "Vehicle Unit 35'",
    value: "vehicle_unit_35",
  },
  {
    label: "Vehicle Unit 50'",
    value: "vehicle_unit_50",
  },
] as {
  label: string;
  value: string;
}[];

export const sosmed_sharing = [
  {
    title: "fb",
    url: `https://www.facebook.com/sharer/sharer.php?u=`,
    Icon: FBIcon,
    tooltip: "Share to Facebook",
  },
  {
    title: "linkedin",
    url: `https://www.linkedin.com/sharing/share-offsite/?url=`,
    Icon: LinkedinIcon,
    tooltip: "Share to LinkedIn",
  },
  {
    title: "twitter",
    url: `https://twitter.com/intent/tweet?url=`,
    Icon: TwitterIcon,
    tooltip: "Share to Twitter",
  },
  {
    title: "email",
    url: `mailto:?subject=`,
    Icon: MailIcon,
    tooltip: "Share to Email",
  },
] as {
  title: string;
  url: string;
  Icon: (props: any) => JSX.Element;
  tooltip: string;
}[];
