import Images from "@/src/component/image/Images";
import { testimoni } from "@/src/helpers/list";
import { ReviewStrapiProps } from "@/src/utils/globalTypes";
import Image from "next/image";
import { useState } from "react";

const TestimoniSection = ({
  title = "Over 1K+ Users Trus Us",
  subtitle = `Try Storage Hunt today and see for yourself why it's trusted by so many users!`,
  className,
  containerClassName,
  noName,
  items,
}: {
  title?: string;
  subtitle?: string;
  className?: string;
  containerClassName?: string;
  noName?: boolean;
  items?: ReviewStrapiProps[];
}) => {
  const [activeTesti, setActiveTesti] = useState(2);
  return (
    <>
      {/* TESTIMONI */}
      <div
        className={`px-4.5 2xl:px-default mt-16 flex justify-center lg:container lg:mt-32 ${containerClassName}`}
      >
        <div className="bg-primary-50 p-8.5 flex w-full flex-col items-center justify-center rounded-[2.125rem] bg-[url('/images/bg-pattern.png')] text-center lg:max-w-6xl lg:rounded-[3rem] lg:p-12">
          <h2 className="lg:text-title font-tilt-warp text-[2.25rem]/[2.25rem] text-neutral-900">
            {title}
          </h2>

          <p className="text-regular-normal lg:text-large-normal mt-6 text-neutral-500">
            {subtitle}
          </p>
        </div>
      </div>
      <div className="px-4.5 mt-16 lg:container lg:mt-[3.625rem] lg:px-0">
        <div
          className={`gap-4.5 lg:gap-9.5 flex flex-col items-center justify-center text-center ${className}`}
        >
          <div className="gap-4.5 lg:gap-9.5 flex items-center">
            {items !== undefined ? (
              <>
                {items.map((e, key) => (
                  <div
                    key={key}
                    className="w-fit cursor-pointer"
                    onClick={() => {
                      setActiveTesti(key);
                    }}
                  >
                    <Image
                      src={e.attributes.photo.data.attributes.url}
                      alt={
                        e.attributes.photo.data.attributes.alternativeText ||
                        e.attributes.name
                      }
                      unoptimized
                      width={0}
                      height={0}
                      loading="eager"
                      className={`rounded-full border-[3px] object-cover ${
                        activeTesti === key
                          ? "border-primary-500"
                          : "border-transparent"
                      } ${
                        testimoni[key].className
                      } hover-anime hover:border-primary-500`}
                    />
                  </div>
                ))}
              </>
            ) : (
              <>
                {testimoni.map((e, key) => (
                  <div
                    key={key}
                    className="w-fit cursor-pointer"
                    onClick={() => {
                      setActiveTesti(key);
                    }}
                  >
                    <Images
                      src={e.img}
                      alt={`${title}`}
                      className={`rounded-full border-[3px] object-cover ${
                        activeTesti === key
                          ? "border-primary-500"
                          : "border-transparent"
                      } ${e.className} hover-anime hover:border-primary-500`}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
          {items !== undefined ? (
            <div className="flex flex-col">
              <p
                className={`text-large-normal lg:text-title3/[2.25rem] text-neutral-500 ${
                  noName ? "lg:max-w-[43rem]" : "lg:max-w-[44rem]"
                }`}
              >
                {items[activeTesti].attributes.description}
              </p>
              {!noName && (
                <div className="mt-4.5 flex flex-col gap-2.5">
                  <div className="text-large-none md:text-title3 font-bold text-neutral-900">
                    {items[activeTesti].attributes.name}
                  </div>
                  <div className="text-info-500 text-regular-none font-medium">
                    {items[activeTesti].attributes.location}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <p
                className={`text-large-normal lg:text-title3/[2.25rem] text-neutral-500 ${
                  noName ? "lg:max-w-[43rem]" : "lg:max-w-[44rem]"
                }`}
              >
                {noName
                  ? testimoni[activeTesti].desc_ea
                  : testimoni[activeTesti].desc}
              </p>
              {!noName && (
                <div className="mt-4.5 flex flex-col gap-2.5">
                  <div className="text-large-none md:text-title3 font-bold text-neutral-900">
                    {testimoni[activeTesti].name}
                  </div>
                  <div className="text-info-500 text-regular-none font-medium">
                    {testimoni[activeTesti].address}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TestimoniSection;
